<template>
  <el-dialog :visible.sync="visible" width="790px" append-to-body>
    <div class="el-dialog__title" slot="title">移动文件至 — <b>{{ title }}</b></div>
    <div class="move-modal">
      <GTree
        :tree-data="treeData"
        :default-expanded-keys="defaultExpandedKeys"
        :default-selected-keys="defaultExpandedKeys[0]"
        :replace-fields="{
          key: 'folderId',
          value: 'folderId',
          label: 'folderName',
          children: 'sub'
        }"
        @select="checkFolderAll"
      />
      <div class="selected-file">
        <ul>
          <li v-for="(file, fileIndex) in files" :key="file.folderId">
            <img
              v-if="file.type === 'video'"
              class="img"
              :src="`${file.url}&vframe/png/offset/3|imageMogr2/thumbnail/!12p/gravity/center/crop/!120x120`"
            >
            <img v-else :src="`${file.url}imageslim&imageView2/1/w/120/h/120/q/70`" alt="" @click="previewImg(fileIndex)">
            <span class="file-des">{{ file.name }}</span>
          </li>
        </ul>
        <p class="total-files">共 <b>{{ files.length }}</b> 个文件</p>
      </div>
    </div>
    <div slot="footer">
      <a-button @click="cancelMoveFile">取消</a-button>
      <a-button type="primary" @click="submit">确认</a-button>
    </div>

    <ImageView v-if="isShowImageView" :on-close="closeViewer" :url-list="previewImgList" :z-index="99999" />

  </el-dialog>
</template>

<script>
import GTree from './GTree'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import oldAxios from '../utils/oldAxios'

export default {
  name: 'MoveFile',
  components: {
    GTree,
    'ImageView': ElImageViewer
  },
  data() {
    return {
      title: '',
      visible: false,
      files: [],
      folderId: '',
      defaultExpandedKeys: [],
      treeData: [],
      previewImgList: [],
      isShowImageView: false
    }
  },
  computed: {
    options() {
      return this.$store.getters.folderList
    }
  },
  methods: {
    closeViewer() {
      this.previewImgList = []
      this.isShowImageView = false
    },
    previewImg(index) {
      const srcList = this.files.filter(v => v.type !== 'video').map(v => v.url)
      const startImg = srcList.splice(index, srcList.length)
      const endImg = srcList.splice(0, index)
      this.previewImgList = [...startImg, ...endImg]
      this.isShowImageView = true
    },
    cancelMoveFile() {
      this.folderId = ''
      this.files = []
      this.defaultExpandedKeys = []
      this.visible = false
    },
    checkFolderAll(data) {
      this.title = data.folderName
      this.folderId = data.folderId
    },
    show(files, file) {
      this.visible = true
      this.files = files
      this.title = `${file.folderName}`
      this.defaultExpandedKeys = [file.folderId]
      oldAxios.get('/media/folder/tree').then(res => {
        if (res.code === '0000') {
          this.treeData = [res.data]
        }
      })
    },
    async submit() {
      const { files, folderId } = this
      const ids = JSON.stringify(files.map(v => v.id))
      const response = await oldAxios.post('/media/media/remove', { ids, folderId })
      if (response.code === '0000') {
        this.$message.success('移动成功')
        this.visible = false
        this.$emit('change')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.move-modal{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 450px;
  .g-tree{
    width: 240px;
    height: 100%;
    overflow-y: auto;
    padding: 12px;
    border-right: 1px #e8e8e8 solid;
  }
  .selected-file{
    width: calc(100% - 240px);
    height: 100%;
    position: relative;
    ul{
      width: 100%;
      height: calc(100% - 32px);
      padding: 12px 0 12px 12px;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      li{
        width: 120px;
        height: 120px;
        margin: 0 12px 12px 0;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
        box-shadow: $cardShadow;
        border: 1px #409EFF solid;
        position: relative;
        transition: all .3s;
        &:hover{
          transform: scale(1.1);
        }
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
        .file-des{
          display: block;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 12px;
          color: #fff;
          padding: 0 12px;
          height: 32px;
          line-height: 32px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          background: rgba(0, 0, 0, .6);
        }
      }
    }
    .total-files{
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      border-top: 1px solid $themeBg;
      background: #fff;
      color: $title2;
      font-weight: bold;
      padding: 12px;
      font-size: 12px;
      b{
        color: $title1;
      }
    }
  }
}
::v-deep {
  .el-dialog__footer{
    padding: 10px 16px;
    border-top: 1px #e8e8e8 solid;
    .ant-btn{
      margin-left: 12px;
    }
  }
  .el-dialog__header{
    padding: 16px 24px;
    border-bottom: 1px #e8e8e8 solid;
    .el-dialog__title{
      color: #475669;
      font-weight: 700;
      font-size: 16px;
      b{
        color: #409EFF;
      }
    }
    .el-dialog__headerbtn{
      font-size: 20px;
      top: 16px;
    }
  }
  .el-dialog__body{
    padding: 0;
  }
}
</style>
